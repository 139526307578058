import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {getAgendaDoc, getUser, getUserRdvPerDate, getUserSubscription} from "../firebase";
import firebase from "firebase/compat/app";
import pinMapGrey from "../assets/mapPinGrey.png";
import timeCheckGrey from "../assets/timecheckGrey.png";
import icRightArrow from "../assets/icRightArrow.svg";
import icLeftArrow from "../assets/icLeftArrow.svg";
import SnackbarWithCustomBackground from "./snackbar";
import {HeaderHome} from "./headerHome";

const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

const monthNames = [
    'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
    'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];


function groupDisplayItems(items) {
    const groupedItems = [];
    // Helper object to keep track of counts
    const dateCountMap = {};
    // Process each item
    for (const item of items) {
        // Extract the day and date part, which is the first two words in the dateStr
        const dayAndDate = item.dateStr.split(" ").slice(0, 2).join(" ");
        const day = item.dateStr.split(" ")[1];
        const month = monthMap[item.dateStr.split(" ")[2]];
        const year = item.dateStr.split(" ")[3];
        // Increment the count for this day and date in the map
        if (dateCountMap[dayAndDate]) {
            dateCountMap[dayAndDate].count += 1;
        } else {
            // If it doesn't exist, initialize it with count = 1 and add the date field
            dateCountMap[dayAndDate] = { count: 1, date: dayAndDate, day:day, month:month, year:year };
        }
    }
    // Convert the map to an array
    for (const key in dateCountMap) {
        groupedItems.push(dateCountMap[key]);
    }

    // Sort the groupedItems array by day in ascending order
    groupedItems.sort((a, b) => a.day - b.day);
    return groupedItems;
}


export default function RdvMonthView() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const realDate = new Date();
    const [items, setItems] = useState([]);
    const [userHasAgenda, setUserHasAgenda] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [timeZoneState, setTimeZoneState] = useState("Europe/Paris");
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [subStatus, setSubStatus] = useState("");
    const [rdvLeft, setRdvLeft] = useState(0);
    const [displayMonth, setDisplayMonth] = useState(monthNames[currentDate.getMonth()]);
    const [displayYear, setDisplayYear] = useState(currentDate.getFullYear());
    const [blockButtons, setBlockButtons] = useState(false);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(currentDate.getMonth());
    const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            let timezone = "Europe/Paris";
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    const currentDate = new Date();
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status){
                            setSubStatus(subObj.status);
                            setRdvLeft(50 - subObj.count);
                        } else {
                            setSubStatus("free");
                            setRdvLeft(0);
                        }
                        const agendaDoc = await getAgendaDoc(user.uid);
                        const userData = await getUser(user.uid);
                        if (userData?.timezone) {
                            timezone = userData.timezone;
                            setTimeZoneState(timezone);
                        }
                        if (agendaDoc) {
                            setUserHasAgenda(true);
                        }
                        const rdvArray = await getUserRdvPerDate(user.uid, currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear(), true);
                        const displayItems = groupDisplayItems(rdvArray);
                        if (displayItems.length>0){
                            setItems(displayItems);
                        }
                    } catch (e) {
                        console.error('Error ', e.message);
                    }
                    setShowSkeleton(false)
                };
                await fetchData();
                setShowSkeleton(false)
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const navigatePreviousMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);

        // Calculate previous month and its year
        const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
        const previousMonthYear = previousMonthIndex === 11 ? currentYear - 1 : currentYear;
        const previousMonthName = monthNames[previousMonthIndex];

        setCurrentMonthIndex(previousMonthIndex);
        setCurrentYear(previousMonthYear);
        setDisplayMonth(previousMonthName);
        setDisplayYear(previousMonthYear);

        const rdvArray = await getUserRdvPerDate(firebase?.auth()?.currentUser?.uid, previousMonthIndex, 0, previousMonthYear, true);
        const displayItems = groupDisplayItems(rdvArray);
        if (rdvArray.length>0){
            setItems(displayItems);
        } else {
            setItems([]);
        }

        setBlockButtons(false);
        setShowBackdrop(false);

    }
    const navigateNextMonth = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);

        const nextMonthIndex = (currentMonthIndex + 1) % 12;
        const nextMonthYear = nextMonthIndex === 0 ? currentYear + 1 : currentYear;
        const nextMonthName = monthNames[nextMonthIndex];

        setCurrentMonthIndex(nextMonthIndex);
        setCurrentYear(nextMonthYear);
        setDisplayMonth(nextMonthName);
        setDisplayYear(nextMonthYear);

        const rdvArray = await getUserRdvPerDate(firebase?.auth()?.currentUser?.uid, nextMonthIndex, 0, nextMonthYear, true);
        const displayItems = groupDisplayItems(rdvArray);
        if (rdvArray.length>0){
            setItems(displayItems);
        } else {
            setItems([]);
        }

        setBlockButtons(false);
        setShowBackdrop(false);

    }

    const navigateToRdv = (day, month, year) => {
        navigate("/rdv?day="+day+"&month="+month+"&year="+year);
    };
    const navigateHome = () => {
        navigate("/home");
    };
    const navigateToRdvPresta = () => {
        if (rdvLeft>0 || (subStatus==="trialing" || subStatus==="active")){
            if (!userHasAgenda){
                handleOpenSnackbar("Cliquez sur 'Paramètres de l'agenda', ajoutez et enregistrez vos paramètres une fois pour commencer à prendre un rendez-vous.")
            } else {
                navigate("/rdvpresta");
            }
        } else {
            handleOpenSnackbar("Il ne vous reste plus de rendez-vous pour ce mois. Passez en forfait premium, ou attendez le mois prochain.")
        }
    };
    const navigateToAgendaParam = () => {
        navigate("/agendaparam");
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style">Vos rendez-vous</span>
                        <div className="button-container">
                            <Button className="button-style button-style-noborders" variant="contained"
                                    onClick={navigateToRdvPresta}>
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Planifier un rendez-vous
                                </Typography>
                            </Button>
                        </div>
                        <div className="button-container" style={{marginTop: "23px"}}>
                            <Button className="button-style-agenda button-style-noborders" variant="contained"
                                    onClick={navigateToAgendaParam}>
                                <Typography variant="button" style={{textTransform: 'none', color: '#FFFFFF'}}>
                                    Paramètres de l'agenda
                                </Typography>
                            </Button>
                        </div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "36px"
                        }}>
                            <IconButton disabled={blockButtons}
                                        onClick={() => navigatePreviousMonth()}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icLeftArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                            <div style={{fontWeight:"700", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                                {displayMonth} {displayYear}
                            </div>
                            <IconButton disabled={blockButtons} onClick={() => navigateNextMonth()}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icRightArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                        </div>

                        <div style={{position:"relative"}}>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={showBackdrop}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                        <div className="date-layout">
                            {items.map((item, index) => (
                                <div
                                    key={index}
                                    className="date-card"
                                    onClick={() => navigateToRdv(item.day, item.month, item.year)}
                                    style={{
                                        background: "white",
                                        color: "black",
                                        cursor: "pointer",
                                        position: "relative" // Required to position the circle absolutely within the card
                                    }}
                                >
                                    <div style={{ margin: "20px" }}>
                                        {item.date}
                                    </div>

                                    {/* Circle containing item.count */}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "6px", // Adjust to give some margin from the top
                                            right: "6px", // Adjust to give some margin from the right
                                            backgroundColor: "#894CF0",
                                            color: "white",
                                            borderRadius: "50%",
                                            width: "21px", // Adjust size as needed
                                            height: "21px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "12px", // Adjust font size as needed
                                        }}
                                    >
                                        {item.count}
                                    </div>
                                </div>
                            ))}
                        </div>


                        {items.length === 0 && !showBackdrop && (
                            <div className="rdv-card" style={{marginTop: "40px"}}>
                                <div style={{margin: "20px"}}>
                                    <span>Aucun rdv ce jour</span>
                                </div>
                            </div>
                        )}

                        <div className="button-container" style={{marginTop: "70px"}}>
                            <Button style={{marginBottom: "100px"}} className="button-style-nogr button-style-borders"
                                    disableElevation variant="contained" onClick={navigateHome}>
                                <Typography variant="button" style={{textTransform: 'none'}}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                                      message={snackMessage} style={{width: "100%"}}/>
                    </div>
                )}
        </div>
    )
}