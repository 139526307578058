import {useNavigate} from "react-router-dom";
import testlogo from "../assets/goodlogo.png";
import React, {useEffect, useRef, useState} from "react";
import diamondIc from "../assets/diamondColored.svg";
import {Fade, IconButton, MenuItem, Typography} from "@mui/material";
import icDiam from "../assets/ic_outline-diamond.svg";
import {getUser, getUserSubscription, logout} from "../firebase";
import firebase from "firebase/compat/app";
import {FaCoffee} from "react-icons/fa";
import icCoffee from "../assets/icCoffee.svg";
import icMenu from "../assets/icMenu.svg";
import ppLeaveIc from "../assets/ppLeaveIc.svg";
import Button from "@mui/material/Button";
import {useDataContext} from "./datacontext";

export function HeaderHome({onMenuClicked}) {
    const { data, updateData } = useDataContext();
    const navigate = useNavigate();
    const [menuVisible, setMenuVisible] = useState(false);
    const [subStatus, setSubStatus] = useState("");
    const [userData, setUserData] = useState({});
    /*const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };*/
    const navigateHome = () => {
        navigate("/home")
    };

    const navigateToProfile = (url) => {
        window.open(url);
    };

    const menuClicked = (e) => {
        setMenuVisible(!menuVisible)
    };

    const menuNavigation = (path) => {
        updateData({});
        navigate(path);
    };

    const divRef = useRef(null);
    const exceptionRef = useRef(null);

    // Function to handle clicks outside of the HeaderHome component
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target) &&
            !exceptionRef.current.contains(event.target)) {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                async function getUserData(){
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        const userData = await getUser(user.uid);
                        setUserData(userData);
                        if (subObj?.status){
                            setSubStatus(subObj.status);
                        }
                    } catch (e){
                        console.log(e);
                    }
                }
                await getUserData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
        }, []);

    const handleLogOut = async () => {
        try {
            await logout();
        } catch (e){
            console.log(e);
        }
    }



    return (
        <div style={{width:"100%", display:"flex", flexDirection:"column", marginBottom:"30px"}}>
            <div style={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignContent:"top", alignItems:"top"}}>
                <img onClick={navigateHome}
                     //onLoad={event => handleImageLoad(event)}
                     className="white"   src={testlogo} alt="fmbg"
                     style={{ width:"105px", height:"36px", cursor:"pointer", marginTop:"4px"}}/>
                <div style={{ color:"white", display:"flex", alignContent:'center', alignItems:'center', justifyContent:'top', marginLeft:"13px"}}>
                    <Button onClick={menuClicked} ref={divRef} style={{margin:"1px", padding: "8px 20px", background:"white", color:"black", border:"1px solid black"}} disableElevation variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', fontWeight:400, fontSize:"14px" }}>
                            Menu
                        </Typography>
                    </Button>{/*
                    <IconButton variant="contained" onClick={menuClicked} ref={divRef}
                                style={{ color:"white", display:"flex", padding:"0"}}>
                       <img src={icMenu} style={{color:"black", width:"25px", height:"25px"}}></img>
                    </IconButton>*/}
                </div>
            </div>

            <div style={{display:"flex", width:"170px", flexDirection:"row", placeSelf:"end", marginRight:"20px", marginTop:"10px"}} ref={exceptionRef}>
                {menuVisible && (
                    <Fade in={true} style={{ zIndex: 2 }}>
                        <div style={{ position: 'relative', left: 0, width:"100%"}}>
                            <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, maxWidth:"100%", minWidth:"100%"}}>
                                <div style={{boxShadow:"0px 4px 15px 0px rgba(0, 0, 0, 0.25)",display:"flex",minWidth:"100%", width:"100%",
                                    flexDirection:"column", backgroundColor:"white", padding:"10px", borderRadius:"15px"}}>
                                    <div style={{ overflowY: "auto" }}>
                                        <MenuItem onClick={()=> (menuNavigation("/rdvMonth"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"linear-gradient(90deg, #FA3464 2.31%, #9747FF 96%)"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            color:"white",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Rendez-vous
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (menuNavigation("/newpresta"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"#4808FF"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            color:"white",
                                                            fontWeight:700,
                                                            textAlign:"center",
                                                            fontSize:"16px",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Créer prestation
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (menuNavigation("/preferences"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"linear-gradient(90deg, #4808FF 2.31%, #9747FF 96%)"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            color:"white",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Modifier profil
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (menuNavigation("/settings"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"#DAE0E5"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            color:"black",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Paramètres
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (menuNavigation("/subscription"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"#DAE0E5"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            color:"black",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Abonnement
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (navigateToProfile("https://shine.faismoibg.com/centre-daide"))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      background:"#DAE0E5"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            color:"black",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Aide
                                                        </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=> (navigateToProfile("https://faismoibg.com/"+userData.urlPerso))}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                      border:"1px solid black",
                                                      display:"flex",
                                                      justifyContent:"center"
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            fontWeight:700,
                                                            fontSize:"16px",
                                                            color:"black",
                                                            textAlign:"center",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Profil public
                                                        </span>
                                            <img src={ppLeaveIc} style={{marginRight:"16px"}}/>
                                        </MenuItem>
                                        {subStatus === "trialing" || subStatus==="active" ? (
                                            <MenuItem disabled
                                                      style={{
                                                          zIndex:1000,
                                                          marginBottom:"5px",
                                                          padding: "10px 10px",
                                                          borderRadius:"10px",
                                                          display:"flex",
                                                          justifyContent:"center",
                                                          opacity:"100%"
                                                      }}
                                            >
                                                        <span style={{
                                                            overflow: "hidden",
                                                            fontWeight:400,
                                                            fontSize:"16px",
                                                            textAlign:"center",
                                                            color:"black",
                                                            marginRight:"10px",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Premium
                                                        </span>
                                                <img src={diamondIc} style={{ width:"20px", height:"20px"}}></img>
                                            </MenuItem>
                                        ) : (
                                            <MenuItem disabled
                                                      style={{
                                                          zIndex:1000,
                                                          marginBottom:"5px",
                                                          padding: "10px 10px",
                                                          borderRadius:"10px",
                                                          display:"flex",
                                                          justifyContent:"center",
                                                          opacity:"100%"
                                                      }}
                                            >
                                                        <span style={{
                                                            overflow: "hidden",
                                                            fontWeight:400,
                                                            fontSize:"16px",
                                                            textAlign:"center",
                                                            color:"black",
                                                            marginRight:"10px",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Gratuit
                                                        </span>
                                                <img src={icCoffee} style={{ width:"20px", height:"20px"}}></img>
                                            </MenuItem>
                                        )}
                                        <MenuItem onClick={()=> handleLogOut()}
                                                  style={{
                                                      zIndex:1000,
                                                      marginBottom:"5px",
                                                      padding: "10px 10px",
                                                      borderRadius:"10px",
                                                  }}
                                        >
                                                        <span style={{width: "100%",
                                                            overflow: "hidden",
                                                            fontWeight:400,
                                                            fontSize:"16px",
                                                            textAlign:"center",
                                                            color:"black",
                                                            textDecoration: "underline",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis"}}>
                                                            Déconnexion
                                                        </span>
                                        </MenuItem>
                                    </div>{/*
                                            <div className="button-container" style={{marginTop:"15px"}}>
                                                <Button disableElevation={true} onClick={handleValidateSelection} style={{marginBottom:"15px"}} className="button-style-noshadow button-style-noborders" variant="contained">
                                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                                        Valider
                                                    </Typography>
                                                </Button>
                                            </div>*/}
                                </div>
                            </div>
                        </div>
                    </Fade>
                )}
            </div>

        </div>



    )

}